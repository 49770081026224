import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";





function Home() {

  return (

    <div>
      <h1>Graphique niveau eau </h1>
      <NavLink
        to="/capteur1"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      > 
        Capteur1
      </NavLink> || 
      <NavLink
        to="/capteur2"
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "active" : ""
        }
      >
        Capteur2
      </NavLink>

    </div>
  )
}

export default Home;