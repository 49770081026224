
import './App.css';
import Home from './components/Home/Home';
import Capteur2 from './components/Capteur2/Capteur2';
import Capteur1 from './components/Capteur1/Capteur1';

import { Route, Routes } from 'react-router-dom';
function App() {

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/capteur1' element={<Capteur1 />} />
        <Route path='/capteur2' element={<Capteur2 />} />

      </Routes>
    </div>
  );
}

export default App;
